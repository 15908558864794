<template lang="pug">
    #itemAcessoRestrito
        ul
            li(v-for="item in items")
                a(:href="item.link")
                    .img
                        img(:src="item.image").image
                    h4 {{ item.title }}
</template>

<script>
import { props } from '@/mixins/component'

export default {
    name: "component-item-acesso-restrito",
    props,
    data() {
        return {
            items: []
        }
    },
    mounted() {
        this.items = this.contents.content.map(item => ({
            ...item,
            image: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${item.image}`
        }))
    }
}
</script>

<style lang="stylus" scoped src="./AcessoRestritoItem.styl"></style>